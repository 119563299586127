
import './App.css';
import RouterPage from './Component/router-page/RouterPage';

function App() {
  return (
    <div className="App">
      <RouterPage></RouterPage>
    </div>
  );
}

export default App;
